// Modules
import React, { useLayoutEffect, useEffect, useState, createContext, useMemo } from 'react';
import { useRoutes, useLocation, Navigate, useNavigate } from 'react-router-dom';

// Common CSS
import '_Asset/css/reset.css'
import '_Asset/css/icofont.min.css'  

// User
import { Main, About, Contact, Exchange, NewsList, NewsDt, Fido, Trading, Forbidden } from 'User/Page';
import { Header, Footer } from 'User/include';
import i18next from "User/Lang/i18n"

// Admin
import { AccountDt, AccountList, AdmNews, AdmNewsDt } from 'Admin/Page';
import Login from 'Admin/Page/Login';
import { AdmHeader, AdmSideMenu } from 'Admin/include';



export const UserContext = createContext({
  htmlLang: () => {},
  setHtmllnag: () => {},
})

const App = () => {

  const location = useLocation();
  const path = location.pathname
  const pathChk = path.split('/')[1];
  const navigate = useNavigate();
  const [htmlLang, setHtmlLang] = useState(i18next.languages[1]); // 기본 Language 세팅
  const value = useMemo(() => ({ htmlLang, setHtmlLang}), [htmlLang, setHtmlLang])

  const sessionIsLogin = JSON.parse(localStorage.getItem("accSessionData"))?.isLogin;
  const [isLogin, setIsLogin] = useState(sessionIsLogin || false);
  const [userName, setUserName] = useState('');


  /*========================================================================
  * Page Router
  ========================================================================*/
  const forbPage = location.pathname.split('/')[1] === "404";

  const pageList = [
    { path: "/", element: <Main /> },
    { path: "/about", element: <About /> },
    { path: "/contact", element: <Contact /> },
    // { path: "/exchange", element: <Exchange /> },
    { path: "/news", element: <NewsList /> },
    // { path: "/news/:id", element: <NewsDt /> },
    { path: "/solutions", element: <Navigate replace to="/solutions/fido" /> },
    { path: "/solutions/fido", element: <Fido /> },
    { path: "/solutions/trading", element: <Trading /> },

    { path: "member-login", element: <Login setUserName={setUserName} setIsLogin={setIsLogin} isLogin={isLogin} /> },
    isLogin &&
    (
      { path: "/admin", element: <Navigate replace to="admin/news" /> },
      {
        path: "/admin",
        children: [
          // News
          { path: "news", element: <AdmNews /> },
          { path: "news/:key", element: <AdmNews /> },
          { path: "news/view", element: <AdmNewsDt userName={userName}/> },

          // Account
          { path: "account", element: <AccountList /> },
          { path: "account/:key", element: <AccountList /> },
          { path: "account/view", element: <AccountDt userName={userName}/> },
        ]
      }
    ),
    { path: "*", element: <Navigate replace to="/404" /> },
    { path: "/404", element: <Forbidden/> },
  ];

  const neoframePage = useRoutes(pageList);


  /*========================================================================
  * Scroll Event
  ========================================================================*/
  const scrollEv = () => {    
    window.addEventListener("scroll", (e) => {
      subTitPos()
    })
  }
  
  const subTitPos = () => {  
    const subContTit = document.querySelectorAll('.subContTit');
    subContTit.forEach(s => {
      const elHeight = s.closest('.contBox').clientHeight / 1.5;
      if(window.scrollY + elHeight >= s.offsetTop){      
        s.classList.add("on")
      }
    })
  }

  useLayoutEffect(()=> {
    const hash = location.hash;
    if(hash === '#contact' || hash === '#news'){
      const header = document.querySelector("header").getBoundingClientRect().height;
      const itm = document.querySelector(hash);
      let pos = itm.getBoundingClientRect().top - header;
      window.scrollBy(0, pos)
    } else {
      window.scrollTo(0, 0)
    }    
    subTitPos();
    scrollEv();    
  }, [location])


  return (
    <>
      { // 404 Page
        forbPage || pathChk === 'member-login' ? 
          <React.Fragment>
            {neoframePage}
          </React.Fragment>
        :
          <React.Fragment>
            {
              pathChk === 'admin' ? 
                // Admin Page
                <React.Fragment>
                  <AdmHeader setUserName={setUserName} userName={userName} isLogin={isLogin} setIsLogin={setIsLogin} />
                  <div className="admLayoutGrid">
                    <AdmSideMenu />

                    <div className="layoutContents adm">
                      {neoframePage}
                    </div>
                  </div>
                </React.Fragment>
              :
                // User Page
                <div className="layoutGrid">
                  <UserContext.Provider value={value}>
                    <Header />
                    <div id="contents">
                      {neoframePage}
                    </div>
                    <Footer />
                  </UserContext.Provider>
                </div>
            }
          </React.Fragment>
      }
    </>
  )
}

export default App;