// Modules
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";

// API
import { NeoNews/* , NeoNewsUpload */ } from '_Route/Api';

const AdmNeoNewsDt = ({userName}) => {
  
  const location = useLocation();
  const path = location.pathname.split('/')[1];

  const newsUuid = sessionStorage.getItem('newsId');
  const curType = newsUuid ? '수정' : '등록';
  const useGroup = ['미사용', '사용']; // 사용 여부 배열
  const curDate = moment(new Date()).format('YYYY년 MM월 DD일'); // 작성일

  const [info, setInfo] = useState({
    // news_title: '',
    news_content: '',
    news_use: 1,
    news_lang: 'ko',
    // news_file: '',
    // news_dfile: '',
  })
  const { /* news_title, */ news_content, news_use, news_lang/* , news_file, news_dfile */ } = info;
  
  const newsTab = [
    {name: '한글 뉴스', value: 'ko'},
    {name: '영문 뉴스', value: 'en'},
  ]

  
  /*========================================================================
  * Input onChange Func
  ========================================================================*/
  // onChange Info
  const onChange = (e) => {
    const { name, value } = e.target;
    setInfo({...info, [name]: value});
  }

  // onChange Info - Image URL
  // const [fileName, setFileName] = useState([]); // 추가된 모든 Image Array

  // const onChangeFile = () => {
  //   let fnFileArr = [] // 마지막 남은 Image Array
  //   fileName.map(list => {
  //     if (news_content.includes(list)) {
  //       fnFileArr.push(list)
  //     }
  //   })

  //   // 삭제된 Image Array
  //   const dfile = fileName.filter(it => !fnFileArr.includes(it))
  //   setInfo({
  //     ...info,
  //     news_file: fnFileArr.length !== 0 ? fnFileArr : undefined,
  //     news_dfile: dfile.length !== 0 ? dfile : undefined,
  //   })
  // }
  

  /*========================================================================
  * GET Current Neoframe News Data
  ========================================================================*/
  const newsData = () => {
    axios({
      method: 'GET',
      url: NeoNews
    })
    .then(res => {      
      const result = res.data.result.find(id => id.news_uuid === newsUuid);

      if (curType === '수정') {
        Object.entries(result).map(key => {
          if(Object.keys(info).includes(key[0])) {
            info[key[0]] = key[1]
          }
        })
        setInfo({...info})
      }
    })
  }

  useEffect(() => {
    newsData();
  },[])


  /*========================================================================
  * POST / PUT / DELETE Neoframe News Data
  ========================================================================*/
  const Confirm = () => {
    if (curType === '등록') { newsAdd() }
    else { newsUpdate() }
  }

  const newsAdd = () => { // POST
    info.news_author = userName || '관리자'
    axios({
      method: 'POST',
      url: NeoNews,
      data: info
    })
    .then(res => {
      alert("Neoframe News 등록을 완료하였습니다.")
      window.history.back()
      // console.log(res, "Neoframe News POST Success")
    })
    .catch(err => {
      if (err.response.data.message) {
        alert(err.response.data.message)
      }
      // console.error(err, "Neoframe News POST Error...")
    })
  }

  const newsUpdate = () => { // PUT
    info.news_uuid = newsUuid
    info.news_ma = userName || '관리자'
    axios({
      method: 'PUT',
      url: NeoNews,
      data: info
    })
    .then(res => {
      alert("Neoframe News 수정을 완료하였습니다.")
      window.history.back()
      // console.log(res, "Neoframe News Update Success")
    })
    .catch(err => {
      if (err.response.data.message) {
        alert(err.response.data.message)
      }
      // console.error(err, "Neoframe News Update Error...")
    })
  }

  const newsDelete = () => { // DELETE
    axios({
      method: 'DELETE',
      url: NeoNews,
      data: {
       news_uuid: newsUuid
      }
    })
    .then(res => {
      alert("게시물 삭제를 완료하였습니다.")
      window.history.back()
      // console.log(res, "Neoframe News Delete Success")
    })
    .catch(err => {
      console.error(err, "Neoframe News Delete Error...")
    })
  }


  /*========================================================================
  * Ckeditor Image Upload Plugin
  ========================================================================*/
  // const uploadAdapter = (loader) => {
  //   let body = new FormData();

  //   return {
  //     upload: () => {
  //       return new Promise((resolve, reject) => {
  //         loader.file.then((file) => {       
  //           body.append("file", file)
            
  //           axios({
  //             method: "POST",
  //             url: NeoNewsUpload,
  //             data: body,              
  //             headers: {'content-type': `multipart/form-data;`},
  //           })
  //           .then(res => {
  //             // if (res.data.url) {
  //             //   setFileName(prevList => [...prevList, res.data.url])
  //             // }
  //             resolve({
  //               default: res.data.url,
  //             })
  //           })
  //           .catch((err) => {
  //             console.error(err)
  //             reject(err);
  //           });
  //         });
  //       });
  //     }
  //   };
  // }

  // const uploadPlugin = (editor) => {
  //   editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
  //     return uploadAdapter(loader);
  //   };
  // }
  

  return (
    <>
      <div className="adContTitGroup">
        <h2><span className='titLabel'>{path.charAt(0).toUpperCase() + path.slice(1)}</span> News {curType}</h2>
        <p>Home / {path.charAt(0).toUpperCase() + path.slice(1)} / News 관리 / News {curType}</p>
      </div>

      <div className="adContArea detail">
        <fieldset className="left">
          <legend>News 글 수정</legend>

          {/* 사용여부 [news_use] */}
          <div className="fieldGroup row">
            <label>사용여부<span className="redTxt">*</span></label>

            <div className="field">
              {useGroup.map((item, i) =>
                <div className="checkGroup" key={i}>
                  <input type="radio" className="check sm blue" name="news_use" id={`news_use_${i}`} value={i} onChange={(e) => onChange(e)} checked={Number(news_use) === i} />
                  <label htmlFor={`news_use_${i}`}>{item}</label>
                </div>
              )}
            </div>
          </div>

          {/* 언어 [news_lang] */}
          <div className="fieldGroup row">
            <label>언어<span className="redTxt">*</span></label>

            <div className="field">
              <div className="tabList">
                {newsTab.map((key, i) => 
                  <button key={i} type="button" className={`btn xs ${news_lang === key.value ? 'sfrtBlue' : 'blue_edge'} round_10`} name="news_lang" value={key.value} onClick={(e) => onChange(e)}>{key.name}</button>
                )}
              </div>
            </div>
          </div>

          {/* 제목 [news_title] */}
          {/* <div className="fieldGroup row">
            <label>제목<span className="redTxt">*</span></label>

            <div className="field full">
              <input type="text" className="input md round_10" name="news_title" value={newsItm ? newsItm.news_title : ''} placeholder="제목을 입력해주세요." />
            </div>
          </div> */}

          {/* 내용 [news_content] */}
          <div className="fieldGroup row">
            <label>내용<span className="redTxt">*</span></label>

            <div className="field full">
              <CKEditor
                editor={ClassicEditor}
                config={{placeholder: "내용을 입력하세요."}}
                data={news_content}
                // onReady={(editor) => {
                //   uploadPlugin(editor)
                // }}
                onChange={(event, editor) => {
                  {(news_content || news_content === "" || curType === "등록") &&
                  setInfo({
                    ...info,
                    news_content: editor.getData()
                  })}
                }}
                // onBlur={() => onChangeFile()}
              />
            </div>
          </div>

          {/* 작성 / 수정자 */}
          <div className="fieldGroup row">
            <label>{curType === '등록' ? '작성' : '수정'}자</label>

            <div className="field full">
              <input type="text" className="input md round_10" value={userName || '관리자'} disabled />
            </div>
          </div>

          {/* 작성 / 수정 일시 */}
          <div className="fieldGroup row">
            <label>{curType === '등록' ? '작성' : '수정'} 일시</label>

            <div className="field full">
              <input type="text" className="input md round_10" value={curDate} disabled />
            </div>
          </div>
        </fieldset>

        <div className="btnArea">
          <button type="button" className="btn sm sfrtBlue round_10" onClick={Confirm}>{curType} 완료</button>
          <button type="button" className="btn sm blue_edge round_10" onClick={() => window.history.back()}>취소</button>
          {curType === '수정' && <button type="button" className="btn sm red round_10" onClick={() => { if(window.confirm(`정말 해당 뉴스를 삭제하시겠습니까?`)) newsDelete() }}>삭제</button>}
        </div>
      </div>
    </>
  )
}

export default AdmNeoNewsDt;