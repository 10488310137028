/* ========================================================================
* Common Admin Functions
========================================================================*/
/**
 * [String + Number] 정렬 함수 (ASC, DESC)
 * @param { Array } arr 
 * @param { string } key 
 * @param { string } type [ASC, DESC]
 * @returns { Array }
 */
 export const getValueOfKey = (arr, key, type) => {

  return arr.sort((a, b) => {
    const valueOfKeyA = isNaN(a[key]) ? a[key].toUpperCase() : a[key];
    const valueOfKeyB = isNaN(a[key]) ? b[key].toUpperCase() : b[key];
    let rtVal_1, rtVal_2;

    switch(type){
      case 'ASC' :
        rtVal_1 = valueOfKeyA;
        rtVal_2 = valueOfKeyB;
        break;
      case 'DESC' :
        rtVal_1 = valueOfKeyB;
        rtVal_2 = valueOfKeyA;
        break;
    }
    if (isNaN(a[key])) {
      return rtVal_1.localeCompare(rtVal_2, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    } else {
      return rtVal_1 - rtVal_2
    }
  });
};


/**
 * Paging 함수
 * @param { number } curPage 
 * @param { number } blockSize 
 * @param { number } pageSize 
 * @param { number } count 
 * @returns { count: number, curPage: number, startBlock: number, endBlock: number, pageCount: number }
 */
export const Paging = (curPage, blockSize, pageSize, count) => {

  const curBlock = Math.floor((curPage != 1 ? curPage - 1 : 1) / blockSize)
  const pageCount = Math.floor((count / pageSize) + (count % pageSize == 0 ? 0 : 1))
  
  const startBlock = pageCount == 0 ? 0 : (curBlock * blockSize) + 1
  const endBlock = startBlock + (blockSize - 1) > pageCount ? pageCount : startBlock + (blockSize - 1)

  return {count: count, curPage: curPage, startBlock: startBlock, endBlock: endBlock, pageCount : pageCount};
}


/**
 * 테이블 정렬 아이콘 상태 변경 함수
 * @param { number } curIdx 
 * @param { number } sortIdx 
 * @param { string } sortState expand, up(DECS), down(ASC)
 * @returns { string }
 */
export const SetSortIco = (curIdx, sortIdx, sortState) => {
  if(curIdx === sortIdx) {
    switch(sortState){
      case 'expand' : // 기본
        return 'up';
      case 'up' : // 오름차순 정렬
        return 'down';
      case 'down' : // 내림차순 정렬
        return 'expand';
    }
  } else {
    return 'up';
  }
}


/** // expand default로 수정 
 * 전체 리스트 정렬 및 현재 페이지 리스트 출력 함수
 * @param { object } sortData 
 * @param { number } sortIdx 
 * @param { string } sortState expand, up(DECS), down(ASC)
 * @param { number } curPage 
 * @param { number } pageSize 
 * @returns { object }
 */
export const CurPageList = (sortData, sortType, sortState, curPage, pageSize) => {

  const CurListFilter = (obj) => {
    return obj.filter((key, i) =>
      (curPage-1) * pageSize <= i && i < curPage * pageSize
    )
  }

  switch(sortState){
    case 'expand' : // 기본
      return(CurListFilter(sortData, curPage, pageSize))

    case 'up' : // 오름차순 정렬
      const ascSortData = getValueOfKey(sortData, sortType, 'ASC')
      return(CurListFilter(ascSortData, curPage, pageSize));

    case 'down' : // 내림차순 정렬
      const descSortData = getValueOfKey(sortData, sortType, 'DESC')
      return(CurListFilter(descSortData, curPage, pageSize));
  }
}


/**
 * 체크박스 전체 선택 함수
 * @param { boolean } checked 
 * @typedef { object } resData
 * @param { string } key
 * @returns { number[] }
 */
export const HandleAllCheck = (checked, resData, key) => { 
  if (checked) {
    const idArray = [];
    resData.forEach((el, i) => idArray.push(el[key]));
    return(idArray);
  } else {
    return([]);
  }
}


/**
 * 체크박스 단일 선택 함수
 * @param { boolean } checked 
 * @param { number } id 
 * @param { number[] } checkItems 
 * @returns 
 */
export const HandleSingleCheck = (checked, id, checkItems) => {
  if (checked) {
    return(prev => [...prev, id]);
  } else {
    return(checkItems.filter(el => el !== id));
  }
}


/**
 * 검색어 텍스트 하이라이팅 함수 
 * @param { String } text // 전체 콘텐츠 내용
 * @param { String } query // 검색어
 * @returns 
 */
export const HighlightedText = (text, query) => {
  if (query !== '' && text.includes(query)) {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));

    const highlightTxt = parts.map(part =>
      part?.toLowerCase() === query?.toLowerCase() ? (
        `<mark>${part}</mark>`
      ) : (
        part
      )
    )
    return highlightTxt.join('')
  }
  return text;
}