// Modules
import { useTranslation } from 'react-i18next';
import dompurify from "dompurify"

// CSS
import '_Asset/css/User/solutions.css'

const Trading = () => {

  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;
  
  return (
    <div className="container">
      
      <div className="inner">
        <div className="titWrap">
          <h2 className="contTit">{t('trading_tit')}</h2>
        </div>

        {/* SmartCS-MCA */}
        <div className="contBox">
          <p className="subContTit">SmartCS-MCA</p>

          <div className="subCont col">
            <div className="infoTxt">
              <p>
                <span className="goldTxt">SmartCS-MCA</span> {t('trading_mca_info')}
              </p>
            </div>

            <img src={require("_Asset/img/solutions/trading_01.png")} alt="SmartCS-MCA" />

            <h3 className="subTit_02">{t('features_txt')}</h3>
            <div className="infoTxt">
              <p dangerouslySetInnerHTML={{__html: sanitizer(t('trading_mca_features'))}}></p>
            </div>
          </div>
        </div>
        {/* // SmartCS-MCA */}

        {/* SmartCS-TSA */}
        <div className="contBox">
          <p className="subContTit">SmartCS-TSA</p>

          <div className="subCont col">
            <div className="infoTxt">
              <p>
                <span className="goldTxt">SmartCS-TSA</span> {t('trading_tsa_info')}
              </p>
            </div>
            
            <img src={require("_Asset/img/solutions/trading_02.png")} alt="SmartCS-TSA" />

            <h3 className="subTit_02">{t('features_txt')}</h3>
            <div className="infoTxt">
              <p dangerouslySetInnerHTML={{__html: sanitizer(t('trading_tsa_features'))}}></p>
            </div>
          </div>
        </div>
        {/* // SmartCS-TSA */}

        {/* Pinpoint Advisor */}
        <div className="contBox">
          <p className="subContTit">Pinpoint Advisor</p>

          <div className="subCont col">
            <div className="infoTxt">
              <p>
                <span className="goldTxt">Pinpoint Advisor</span> {t('trading_advisor_info')}
              </p>
            </div>

            <img src={require("_Asset/img/solutions/trading_03.png")} alt="Pinpoint Advisor" />

            <h3 className="subTit_02">{t('features_txt')}</h3>
            <div className="infoTxt">
              <p dangerouslySetInnerHTML={{__html: sanitizer(t('trading_advisor_features'))}}></p>
            </div>
          </div>
        </div>
        {/* // Pinpoint Advisor */}

      </div>
   </div>
  )
}

export default Trading;