// Modules
import { useCallback } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dompurify from "dompurify";
import { useState, useEffect } from 'react';

// CSS
import '_Asset/css/Admin/Page/admin.css'

// API
import { Account, AccUse } from '_Route/Api';

// Component
import {
  Paging, // Paging
  CurPageList, // 전체 리스트 정렬 및 현재 페이지 리스트 출력
  SetSortIco, // 정렬 아이콘 변경
  HandleSingleCheck, HandleAllCheck, // 체크박스 단일, 전체 선택
  HighlightedText // 검색어 하이라이팅
} from 'Admin/Component/AdFunc';
import Pagination from 'Admin/Component/Pagination' // Pagination


const AccountList = () => {

  // Use Hooks
  const params = useParams()
  const sanitizer = dompurify.sanitize;
  const navigate = useNavigate()
  const location = useLocation();
  const path = location.pathname.split('/')[1];

  // Column name / Sort var
  const columnList = [
    {name: 'No.', key: 'acc_no'},
    {name: '아이디', key: 'acc_id'},
    {name: '이메일', key: 'acc_email'},
    {name: '이름', key: 'acc_name'},
    {name: '등록일', key: 'acc_fd'},
    {name: '마지막 로그인', key: 'acc_ll'},
    {name: '설정', key: 'acc_use'},
  ]; 


  /*========================================================================
  * Input onChange Func
  ========================================================================*/
  const [searchVal, setSearchVal] = useState(params.key || '');

  const onChangeSearch = useCallback(e => {
    setSearchVal(e.target.value);
  }, []);
  
  const onKeyPress = (e) => { if(e.key == 'Enter') onSearch(); }
  const onSearch = () => {
    navigate(`/admin/account${searchVal === '' ? '' : '/' + searchVal}`);
  }


  /*========================================================================
  * GET FAQ List
  ========================================================================*/
  // Paging 변수
  const [curPage, setCurPage] = useState(1) // 현제 페이지
  const [blockSize, setBlockSize] = useState(5) // Pagination 블럭 갯수
  const [pageSize, setPageSize] = useState(10) // 한 페이지에 노출할 게시물 갯수
  const [count, setCount] = useState('');

  // 컬럼 정렬 변수
  const [sortIdx, setSortIdx] = useState(0);
  const [sortType, setSortType] = useState('');
  const [sortState, setSortState] = useState('expand'); // expand, up(DECS), down(ASC);

  const [acc, setAcc] = useState([]); // Account List Data
  const [accPage, setAccPage] = useState([]); // Account Paging Data

  const AccList = () => {
    axios({
      method: 'GET',
      url: Account,
      params: {
        acc_id: params.key === undefined ? '' : params.key,
      }
    })
    .then(res => {
      // console.log("GET Account List SUCCESS!", res);
      const sortData = res.data.result;
      
      setAcc(CurPageList(sortData, sortType, sortState, curPage, pageSize))
      setAccPage(res.data.pageInfo)
      setCount(res.data.result.length);
    })
    .catch(err => {
      console.error("GET Account List ERROR...", err);
    })
  }

  useEffect(() => {
    AccList()
  }, [location, pageSize, curPage, sortState, sortIdx])

  const detailId = (e) => {
    if(e){
      sessionStorage.setItem('accId', e);
    } else {
      sessionStorage.removeItem('accId');
    }
  }


  /*========================================================================
  * Check Box Function
  ========================================================================*/
  // CheckBox var
  const [checkItems, setCheckItems] = useState([]);
  
  // 일괄 삭제 함수
  const deleteFunc = () => {
    if(checkItems.length === 0) {
      alert('삭제할 계정을 선택해주세요.')
    } else {
      if(window.confirm(`${checkItems.length}개의 계정을 선택하셨습니다. \n정말 삭제하시겠습니까?`)) {
        checkItems.map((id, i) => { accDelete(id, i) })
        setCheckItems([])
      }
    }
  }

  // Delete FAQ Data
  const accDelete = (id, index) => {
    axios({
      method: 'DELETE',
      url: Account,
      data: {
       acc_id: id
      }
    })
    .then(res => {
      if (index === checkItems.length - 1) {
        AccList()
      }
      // console.log(res, "Account Delete Success")
    })
    .catch(err => {
      console.error(err, "Account Delete Error...")
    })
  }

  // 사용 여부 변경
  const accUpdateUse = (accId) => {
    axios({
      url: AccUse,
      method: 'PUT',
      data: {
        acc_id: accId
      }
    })
    .then(res => {
      AccList()
    })
    .catch(err => {
      console.error("PUT Account List ERROR...", err);
    })
  }


  return (
    <>
      <div className="adContTitGroup">
        <h2>Account</h2>
        <p>Home / 관리자 설정 / Account</p>
      </div>

      <div className="adContArea list">

        <div className="flexBox between">
          <div className="adListBox">
            <button type="button" className="btn xs red round_10" onClick={deleteFunc}>선택 삭제</button>
            
            <div className="fieldGroup row gap">
              <label>노출 게시물 수</label>

              <div className="field">
                <select name="" className="select sm type_1 round_10" onChange={(e) => setPageSize(e.target.value)} defaultValue="10">
                  <option value="10">10개 씩</option>
                  <option value="30">30개 씩</option>
                  <option value="50">50개 씩</option>
                  <option value="100">100개 씩</option>
                </select>
              </div>
            </div>
          </div>

          <div className="adListBox">
            <div className="fieldGroup row gap">
              <label>콘텐츠 검색</label>

              <div className="field">
                <div className="inputIco">
                  <input type="text" className="input sm type_1 i_rt round_10" value={searchVal} onChange={onChangeSearch} onKeyPress={onKeyPress} placeholder="검색어를 입력해주세요." />
                  <i className="icofont-search"></i>
                </div>
              </div>
            </div>
            
            <button type="button" className="btn xs sfrtBlue round_10" onClick={() => {navigate('/admin/account/view'); detailId();}}>계정 등록</button>
          </div>
        </div>

        <div className="table list_2">
          <table>
            <thead>
              <tr>
                <th className="check">
                  <div className="checkGroup">
                    <input type="checkbox" className='check sm blue' id="allCheck" onChange={(e) => setCheckItems(HandleAllCheck(e.target.checked, acc, 'acc_id'))} checked={checkItems.length === acc.length && checkItems.length !== 0 ? true : false} />
                    <label htmlFor="allCheck"></label>
                  </div>
                </th>
                {columnList.map((list, i) =>
                  <th key={i} onClick={() => {setSortState(SetSortIco(i, sortIdx, sortState)); setSortIdx(i); setSortType(list.key)}}>
                    {list.name}
                    <i className={`icofont-rounded-${i === sortIdx ? sortState : 'expand'}`}></i>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {
                acc.map((list, i) => 
                  <tr key={i}>
                    <td>
                      <div className="checkGroup">
                        <input type="checkbox" className='check sm blue' id={`check_${i}`} onChange={(e) => setCheckItems(HandleSingleCheck(e.target.checked, list.acc_id, checkItems))} checked={checkItems.includes(list.acc_id) ? true : false} />
                        <label htmlFor={`check_${i}`}></label>
                      </div>
                    </td>
                    <td>{list.acc_no}</td>
                    <td dangerouslySetInnerHTML={{ __html: HighlightedText(sanitizer(list.acc_id), params.key)}}></td>
                    <td dangerouslySetInnerHTML={{ __html: HighlightedText(sanitizer(list.acc_email), params.key)}}></td>
                    <td dangerouslySetInnerHTML={{ __html: HighlightedText(sanitizer(list.acc_name), params.key)}}></td>
                    <td>{list.acc_fd}</td>
                    <td>{list.acc_ll}</td>
                    <td style={{ width: '125px' }}>
                      <div className="btnArea full col">
                        <button type="button" className={`btn xs ${Number(list?.acc_use) === 1 ? 'sfrtBlue' : 'blue_edge'} round_10`} onClick={() => accUpdateUse(list.acc_id)}>{Number(list?.acc_use) === 1 ? '사용' : '미사용'}</button>
                        <button type="button" className="btn xs green round_10" onClick={() => {navigate("/admin/account/view"); detailId(list.acc_id); }}>정보 수정</button>
                      </div>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>

        <div className="flexBox between paginationBox">
          <p>Showing {curPage} to {pageSize} of {accPage.count} entries</p>

          <Pagination
            type="type_1"
            size="sm"
            curPage={curPage}
            setCurPage={setCurPage}
            pageSize={pageSize}
            blockSize={blockSize}
            startBlock={Paging(curPage, blockSize, pageSize, count).startBlock}
            endBlock={Paging(curPage, blockSize, pageSize, count).endBlock}
            pageCount={Paging(curPage, blockSize, pageSize, count).pageCount}
          />
        </div>
      </div>
    </>
  )
}

export default AccountList;