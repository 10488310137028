// Modules
import { useTranslation } from 'react-i18next';

// CSS
import '_Asset/css/User/forbidden.css'


const Forbidden = () => {

  const { t } = useTranslation();

  return (
    <div className="forbidden">
      <div className="inner">
        <button type="button" className="closeIco" onClick={() => window.history.back(-1)}></button>
        <span>404 Page</span>

        <div className="forbIco">
          <i className="icofont-exclamation-circle"></i>
        </div>

        <div className="forbTxtGroup">
          <h1 className="errCode">404</h1>

          <p className="errTit">Page Not Found!</p>
          <span className="txtMsg">
            {t('forbidden_msg')}
          </span>
          <button type="button" className="btn sm round_10 sfrtBlue" onClick={() => window.history.back(-1)}><i className="icofont-swoosh-right"></i>{t('forbidden_btn_txt')}</button>
        </div>
        
      </div>
   </div>
  )
}

export default Forbidden;