/*################################################
* API 
################################################*/
const apiHost = process.env.REACT_APP_DB_HOST;

/*================================================
* News
================================================*/
const NeoNews = apiHost + '/api/news';
const NeoNewsUpload = apiHost + '/api/news/upload';
const NeoNewsUse = apiHost + '/api/news/use';

/*================================================
* Login & Account
================================================*/
const Account = apiHost + '/api/account';
const AccLogin = apiHost + '/api/accLogin';
const AccLogout = apiHost + '/api/accLogOut';
const AccTime = apiHost + '/api/accReset_time';
const AccCheck = apiHost + '/api/accLoginCheck';
const AccUse = apiHost + '/api/account/use';


module.exports = {
  NeoNews, NeoNewsUpload, NeoNewsUse,
  Account, AccLogin, AccLogout, AccTime, AccCheck, AccUse,
}