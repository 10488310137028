// Modules
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// CSS
import '_Asset/css/User/news.css'

// API
import { NewsApi } from '_API/Api';

// Component
import { UserContext } from '_Route/App';


const NewsList = () => {

  const { t } = useTranslation();

  const { htmlLang } = useContext(UserContext)
  const [news, setNews] = useState([]);

  const newsItems = () => {
    axios({
      method: 'GET',
      url: NewsApi,
      params: {
        news_lang: htmlLang
      }
    })
    .then(res => {
      setNews(res.data.result)
    })
  }

  useEffect(() => {
    newsItems()
  }, [htmlLang])


  return (
    <div className="container">
      
      <div className="inner">
        <div className="titWrap">
          <h2 className="contTit">{t('news_tit')}</h2>
        </div>
        
        {/* News */}
        {news.map((n, i)  => 
          <div className="contBox" key={i}>
            <h3 className="subTit_02">{htmlLang === 'en' ? new Date(n.news_fd).toDateString() : moment(n.news_fd).format('YYYY년 MM월 DD일')}</h3>
            <div className="infoTxt">
              <div dangerouslySetInnerHTML={ {__html: n.news_content}}></div>
            </div>
          </div>
        )}
      </div>
   </div>
  )
}

export default NewsList;