// Modules
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// CSS
import '_Asset/css/Admin/Page/login.css'

// API
import { AccLogin } from '_Route/Api'


const Login = ({setUserName, setIsLogin, isLogin}) => {

  const navigate = useNavigate()

  const [info, setInfo] = useState({
    acc_id: '',
    acc_pass: '',
  })
  const { acc_id, acc_pass } = info;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInfo({...info, [name]: value});
  }

  // Login Func
  const login = () => {
    if (acc_id === '') {
      alert("아이디를 입력해주세요.")
    }
    else if (acc_pass === '') {
      alert("비밀번호를 입력해주세요.")
    } else {
      axios({
        url: AccLogin,
        method: 'POST',
        data: info
      })
      .then(res => {
        if(res.data.loggedIn){
          const accSessionData = {
            "isLogin": res.data.loggedIn,
            "time": res.data.sessionTime * 60,
          }
          const accData = {
            "accId": res.data.accId,
            "accName": res.data.accName,
          }
          localStorage.setItem("accSessionData", JSON.stringify(accSessionData))
          localStorage.setItem("accData", JSON.stringify(accData))
  
          setUserName(res.data.accName) // 로그인된 회원 이름 설정
          setIsLogin(res.data.loggedIn) // 로그인 여부 설정
          navigate('/admin/news')
        }
      })
      .catch(err => {
        console.error("Login ERROR...", err);
        if (err.response.data.message) {
          alert(err.response.data.message)
        };
      })
    }
  }

  useEffect(() => {
    if (isLogin) {
      navigate('/admin/news')
    }
  }, [isLogin])
  

  return (
    <div className="login">
      <div className="inner">
        <div className="logoGroup">
          {/* <img src={require('_Asset/img/seyfert_logo.svg').default} alt="Seyfert" /> */}
          <h2>NeoFrame Admin</h2>
        </div>
        
        <fieldset>
          <legend>Login</legend>

          <div className="fieldGroup">
            <div className="field">
              <span className='icofont-key-hole'></span>
              <input type="text" className="input lg line" name="acc_id" onChange={(e) => onChange(e)} placeholder="ID" autoFocus={true} />
            </div>

            <div className="field">
              <span className='icofont-key'></span>
              <input type="password" className="input lg line" name="acc_pass" onChange={(e) => onChange(e)} placeholder="Password" maxLength="20" onKeyUp={(e) => e.key === 'Enter' && login()} />
            </div>
          </div>
        </fieldset>

        <div className="btnArea full">
          <button type="button" className='btn sfrtBlue md round_10' onClick={login}>LOGIN</button>
        </div>
      </div>
    </div>
  )
}

export default Login;