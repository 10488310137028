// Modules
import axios from 'axios'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

// CSS
import '_Asset/css/Admin/Include/adHeader.css'
import Logo from '../../_Asset/img/logo.png'


// API 
import { AccCheck, AccLogout, AccTime } from '_Route/Api'



const AdHeader = ({setUserName, userName, isLogin, setIsLogin}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const sessionIsLogin = JSON.parse(localStorage.getItem("accSessionData"))?.isLogin;
  const path = location.pathname

  const Logout = () => {
    axios.defaults.withCredentials = true;
    axios({
      method: 'GET',
      url: AccLogout,
    })
    .then(res => {
      setIsLogin(false)
      localStorage.removeItem('accSessionData')
      localStorage.removeItem('accData')
      navigate('/')
    })
    .catch(err => { 
      console.error(err,"err")
    })
  } 

  const Logged = () => {
    if (isLogin) {
      Logout()
    } else {
      navigate('/')
    }
  }

  const ReSssion = () => {
    axios({
      method: 'GET',
      url: AccTime,
    })
    .then(res => {
      const accSessionData = JSON.parse(localStorage.getItem('accSessionData'))
      accSessionData.time = res.data.retime
      localStorage.setItem('accSessionData', JSON.stringify(accSessionData));
    })
    .catch(err => { 
      console.error(err,"err")
    })
  } 

  // Session Timer / Session 타임 아웃
  const [sessionTime, setSessionTime] = useState('');
  let seconds = 0;
  
  const myUseTime = () => {
    const accSessionData = JSON.parse(localStorage.getItem('accSessionData'))
    const myCount = accSessionData?.time
    if(myCount) {
      seconds = myCount < 0 ? 0 : myCount;

      const hour = parseInt(seconds/3600) < 10 ? '0'+ parseInt(seconds/3600) : parseInt(seconds/3600); 
      const min = parseInt((seconds%3600)/60) < 10 ? '0'+ parseInt((seconds%3600)/60) : parseInt((seconds%3600)/60); 
      const sec = seconds % 60 < 10 ? '0'+seconds % 60 : seconds % 60;
      const useTime = hour+":"+min+":" + sec;
      setSessionTime(useTime);

      accSessionData.time = myCount - 1;
      window.localStorage.setItem("accSessionData", JSON.stringify(accSessionData));
      
      if (Number(myCount) === 1) {
        localStorage.removeItem('accSessionData')
        localStorage.removeItem('accData')
        setIsLogin(false)
        alert("세션이 만료되었습니다. 다시 로그인을 시도해주세요.")
        navigate('/');
      }
    }
  }

  useEffect(() => {
    if(isLogin){
      const time = setInterval(() => {
        myUseTime();
      }, 1000);
      return (() => clearInterval(time))
    }
  }, [isLogin])


  /*========================================================================
  * 회원 로그인 여부 체크
  ========================================================================*/
  const sessionChk = () => {
    axios.defaults.withCredentials = true;
    axios({
      method: 'GET',
      url: AccCheck
    })
    .then(res => {
      const accSessionId = JSON.parse(localStorage.getItem("accData"))?.accId;
      const accSessionTime = JSON.parse(localStorage.getItem("accSessionData"))?.time;

      if(res.data.loggedIn === sessionIsLogin && res.data.accId === accSessionId){
        setUserName(res.data.accName) // 로그인된 회원 정보 설정
        setIsLogin(res.data.loggedIn)
      } else {
        if(path !== '/') {
          Logout()
        }
      }
    })
    .catch(err => {
      console.error(err, 'Login Check Error...')
      navigate('/')
    })
  }

  useEffect(() => {
    sessionChk()
  }, [isLogin, location])


  return (
    <header className="adHeader">
      <div className="adInnerHdr">
        <Link to="/" className="sfrtLogo">
          {/* <img src={Logo} alt="Neoframe"></img> */}
          <span><strong>Neoframe</strong> Admin</span>
        </Link>
      </div>

      <div className="accArea">
        <div className="sessionGroup">
          <p>{sessionTime}</p>
          <button type="button" className="btn sfrtBlue xs round_10" onClick={ReSssion}>세션 갱신</button>
        </div>

        <div className="adProfile">
          <i className="icofont-ui-user"></i>
          <span>{userName} 님</span>
        </div>

        <button type="button" onClick={Logged}>{isLogin ? '로그아웃' : '로그인'}</button>
      </div>
    </header>
  )
}

export default AdHeader;