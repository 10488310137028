// Modules
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

// CSS
import '_Asset/css/Admin/Include/adSideMenu.css'

// Component
import { adMenuList } from 'Admin/Component/AdMenuList'


const ChildMenu = ({child, depth = 1}) => {
  
  const location = useLocation();
  const path = location.pathname;

  const [open, toggleOpen] = useState(false);
  const hasChildren = child.children && child.children.length;

  return (
    <li>
      <Link to={child.url} className={open && child.url.includes(path) ? 'active' : ''} onClick={() => toggleOpen(!open)}>
        <div className="menuTitGroup">
          {child.ico && <i className={child.ico}></i>}
          {child.title}
        </div>

        {hasChildren && <i className="icofont-rounded-right"></i>}
      </Link>
      { 
        open && child.url.includes(path) && hasChildren && 
        child.children?.map((children, i) =>
          <ul key={i} className={`depth_${depth}`}>
            <ChildMenu child={children} depth={depth + 1} />
          </ul>
        )
      }
    </li>
  )
}

const MenuComp = ({list}) => {
  
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="adMenuBox">
      <h2>{list.title}</h2>

      <ul>
        {list.children?.map((child, i) =>
          <ChildMenu key={i} child={child} />
        )}
      </ul>
    </div>
  )
}

const AdSideMenu = () => {
  const [adSideState, setAdSideState] = useState(true)
  return (
    <div className={`adSideMenu ${adSideState ? 'active' : ''}`}>
      {
        adMenuList.map((list, i) => 
          <MenuComp key={i} list={list} />
        )
      }
    </div>
  )
}

export default AdSideMenu;