// Modules
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet"

// CSS
import '_Asset/css/User/include/header.css'

// Component
import i18next from "User/Lang/i18n"
import { UserContext } from '_Route/App';


const Header = () => {

  const { t } = useTranslation()
  const isMinActive = useMediaQuery({ query : "(max-width: 1024px)" });
  const { htmlLang, setHtmlLang } = useContext(UserContext)
  const [isActive, setIsActive] = useState(false);

  const [langActive, setLangActive] = useState(false);
  const [langIdx, setLangIdx] = useState(0);

  // HTML Language 설정
  useEffect(() => {
    setHtmlLang(i18next.languages[1])
  }, [langIdx])

  // Language List / Active
  const langList = [
    {code: "ko-KR", flagUrl: 'South_Korea.svg'},
    {code: "en-US", flagUrl: 'United_States.svg'}
  ]
  
  const langBtn = () => {
    setLangActive(!langActive)
  }


  return (
    <>
      <Helmet htmlAttributes={{ lang: htmlLang }}>
        <title>네오프레임 - NEOFRAME</title>
      </Helmet>
    
      <header>
        <div className="inner">
          {/* Neoframe LOGO */}
          <Link to="/"><img src={require('_Asset/img/logo.png')} alt="NEO frame" /></Link>
          
          {/* Navigation Menu */}
          <nav>
            {
              isMinActive &&
              <button type="button" className={`mobileBtn ${isActive ? 'active': ''}`} onClick={() => setIsActive(!isActive)} ></button>
            }
            <ul className={`${isMinActive ? 'active': ''}`} onClick={() => setIsActive(!isActive)}>
              <li><Link to="/about">{t('header_about_us')}</Link></li>
              <li><Link to="/#news">{t('header_news')}</Link></li>
              {/* <li><Link to="/exchange">{t('header_exchange')}</Link></li> */}
              <li><Link to="/solutions/trading">{t('header_trading')}</Link></li>
              <li><Link to="/solutions/fido">{t('header_fido')}</Link></li>
              <li><Link to="/#contact">{t('header_contact')}</Link></li>
            </ul>
          </nav>

          {/* Language */}
          <div className="langGroup">
            <button className={`${langActive ? 'active' : ''}`} onClick={langBtn}>
              <p><img src={require(`_Asset/img/${langList[langIdx].flagUrl}`)} alt="" /></p>
              <span>{t(`lang_${i18next.language}`)}</span>
              <i className="icofont-rounded-up"></i>
            </button>
            {
              langActive &&
              <ul>
                {langList.map((key, i) => 
                  <li key={i} onClick={() => {i18next.changeLanguage(key.code); setLangIdx(i); langBtn()}}>{t(`lang_${key.code}`)}</li>
                )}
              </ul>
            }
          </div>
        </div>
      </header>
    </>
  )
}

export default Header;