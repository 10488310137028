// Modules
import axios from "axios";
import moment from "moment/moment";
import { useEffect, useState } from "react";

// CSS
import '_Asset/css/Admin/Page/admin.css'

// API
import { Account } from '_Route/Api';


const AccountDt = () => {

  const accId = sessionStorage.getItem('accId');
  const curType = accId ? '수정' : '등록';
  const useGroup = ['미사용', '사용']; // 사용 여부 배열
  const curDate = moment(new Date()).format('YYYY년 MM월 DD일'); // 작성일

  const [info, setInfo] = useState({
    acc_id: '',
    acc_pass: '',
    ...(curType === '수정' && { acc_change_pass: '' }),
    acc_check_pass: '',
    acc_email: '',
    acc_name: '',
    acc_use: 1,
  })
  const { acc_id, acc_pass, acc_change_pass, acc_check_pass, acc_email, acc_name, acc_use } = info;


  /*========================================================================
  * Input onChange Func
  ========================================================================*/
  const onChange = (e) => {
    const { name, value } = e.target;
    setInfo({...info, [name]: value});
  }


  /*========================================================================
  * GET Account Data
  ========================================================================*/
  const accData = () => {
    axios({
      method: 'GET',
      url: Account
    })
    .then(res => {      
      const result = res.data.result.find(r => r.acc_id === accId);

      if (curType === '수정') {
        Object.entries(result).map(key => {
          if(Object.keys(info).includes(key[0])) {
            info[key[0]] = key[1]
          }
        })
        setInfo({...info})
      }
    })
  }

  useEffect(() => {
    accData();
  },[])


  /*========================================================================
  * POST / PUT / DELETE Account Data
  ========================================================================*/
  const Confirm = () => {
    if (curType === '등록') { accAdd() }
    else { accUpdate() }
  }

  const accAdd = () => { // POST
    axios({
      method: 'POST',
      url: Account,
      data: info
    })
    .then(res => {
      alert("계정 등록을 완료하였습니다.")
      window.history.back()
      // console.log(res, "Account POST Success")
    })
    .catch(err => {
      if (err.response.data.message) {
        alert(err.response.data.message)
      }
    })
  }

  const accUpdate = () => { // PUT
    axios({
      method: 'PUT',
      url: Account,
      data: info
    })
    .then(res => {
      alert("계정 수정을 완료하였습니다.")
      window.history.back()
      // console.log(res, "Account Update Success")
    })
    .catch(err => {
      if (err.response.data.message) {
        alert(err.response.data.message)
      }
    })
  }

  const accDelete = () => { // DELETE
    axios({
      method: 'DELETE',
      url: Account,
      data: {
       acc_id: accId
      }
    })
    .then(res => {
      alert("계정 삭제를 완료하였습니다.")
      window.history.back()
      // console.log(res, "Account Delete Success")
    })
    .catch(err => {
      console.error(err, "Account Delete Error...")
    })
  }


  return (
    <>
      <div className="adContTitGroup">
        <h2>Account {curType}</h2>
        <p>Home / 관리자 설정 / Account / Account {curType}</p>
      </div>

      <div className="adContArea detail">
        <fieldset className="left">
          <legend>Account 수정</legend>

          {/* 사용여부 [acc_use] */}
          <div className="fieldGroup row">
            <label>사용여부<span className="redTxt">*</span></label>

            <div className="field">
              {useGroup.map((item, i) =>
                <div className="checkGroup" key={i}>
                  <input type="radio" className="check sm blue" name="acc_use" id={`acc_use_${i}`} value={i} onChange={(e) => onChange(e)} checked={acc_use == i} />
                  <label htmlFor={`acc_use_${i}`}>{item}</label>
                </div>
              )}
            </div>
          </div>

          {/* 아이디 [acc_id] */}
          <div className="fieldGroup row">
            <label>아이디<span className="redTxt">*</span></label>

            <div className="field full">
              <input type="text" className="input md round_10" name="acc_id" value={acc_id} onChange={(e) => onChange(e)} placeholder="아이디를 입력해주세요." disabled={curType === '등록' ? false : true} />
            </div>
          </div>

          {/* 비밀번호 [acc_pass] */}
          <div className="fieldGroup row">
            <label>{`${curType === '등록' ? '' : '현재'} 비밀번호`}<span className="redTxt">*</span></label>

            <div className="field full">
              <input type="password" className="input md round_10" name="acc_pass" value={acc_pass} onChange={(e) => onChange(e)} placeholder="비밀번호를 입력해주세요." />
            </div>
          </div>

           {/* 새 비밀번호 [acc_change_pass] */}
           {curType === '수정' &&
           <div className="fieldGroup row">
            <label>새 비밀번호<span className="redTxt">*</span></label>

            <div className="field full">
              <input type="password" className="input md round_10" name="acc_change_pass" value={acc_change_pass} onChange={(e) => onChange(e)} placeholder="비밀번호를 입력해주세요." />
            </div>
          </div>}

          {/* 비밀번호 확인 [acc_check_pass] */}
          <div className="fieldGroup row">
            <label>{`${curType === '등록' ? '' : '새'} 비밀번호 확인`}<span className="redTxt">*</span></label>

            <div className="field full">
              <input type="password" className="input md round_10" name="acc_check_pass" value={acc_check_pass} onChange={(e) => onChange(e)} placeholder="비밀번호 확인을 입력해주세요." />
            </div>
          </div>

          {/* 이메일 [acc_email] */}
          <div className="fieldGroup row">
            <label>이메일<span className="redTxt">*</span></label>

            <div className="field full">
              <input type="email" className="input md round_10" name="acc_email" value={acc_email} onChange={(e) => onChange(e)} placeholder="이메일을 입력해주세요." />
            </div>
          </div>

          {/* 이름 [acc_name] */}
          <div className="fieldGroup row">
            <label>이름<span className="redTxt">*</span></label>

            <div className="field full">
              <input type="text" className="input md round_10" name="acc_name" value={acc_name} onChange={(e) => onChange(e)} placeholder="이름을 입력해주세요." />
            </div>
          </div>

          {/* 등록 일시 */}
          {curType == '등록' &&
          <div className="fieldGroup row">
            <label>등록 일시</label>

            <div className="field full">
              <input type="text" className="input md round_10" value={curDate} disabled />
            </div>
          </div>}
        </fieldset>

        <div className="btnArea">
          <button type="button" className="btn sm sfrtBlue round_10" onClick={Confirm}>작성 완료</button>
          <button type="button" className="btn sm blue_edge round_10" onClick={() => window.history.back()}>취소</button>
          {curType === '수정' && <button type="button" className="btn sm red round_10" onClick={() => {if(window.confirm(`정말 해당 계정을 삭제하시겠습니까?`)) accDelete() }}>삭제</button>}
        </div>
      </div>
    </>
  )
}

export default AccountDt;