import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import langEn from './lang.en';
import langKo from './lang.ko';

let defaultLeng = navigator.language; // 현재 접속 국가의 Country Code
if (defaultLeng === "ko-KP")
  defaultLeng = "ko-KR"

const resources = {
  'en': {
    translations: langEn
  },
  'ko': {
    translations: langKo
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    lng: defaultLeng, //초기 설정 언어
    fallbackLng: {
      'en-US': ['en-US'],
      defalut: [defaultLeng]
    },
    debug: false,
    defaultNS: 'translations',
    ns: 'translations',
    keySeparator: ".",
    interpolation: {
        escapeValue: false
    },
    react: {
      useSuspense: false
    }
  })

export default i18n;
