// Modules
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dompurify from "dompurify"

// CSS
import '_Asset/css/User/about.css'
import i18n from 'User/Lang/i18n';

// Component
import { UserContext } from '_Route/App';


const About = () => {

  const { t } = useTranslation()
  const { htmlLang } = useContext(UserContext)

  const sanitizer = dompurify.sanitize;


  return (
    <div className="container">
      <div className="inner">
        {
          htmlLang === 'ko' ?

          /* 한국어 전용 회사소개 문구 [2023.02.22 기준 현재 영문 번역이 존재하지 않는 관계로 임시 처리] */
          <React.Fragment>
            <div className="titWrap">
              <h2 className="contTit kor">
                기술과 혁신으로 <br />
                <span>금융에 가치를 더하는</span> 디지털 플랫폼 기업
              </h2>
            </div>
            <div className="titInfoTxt">
              <p>
                네오프레임은 자체 금융채널 솔루션(Multi Channel Interface, 증권 투자정보 등)으로 확보한 차별화된 원천기술과
                18개 증권사에 적용한 축적화된 경험을 바탕으로 국내 최초로 증권사에 클라우드 기반의 AutoScaling
                채널 플랫폼 서비스를 제공 합니다.
              </p>
              <p>
                이를 기반으로 지난 22년간 축적된 디지털 기술 역량과 접목된 업무 역량으로
                원리금수취권 거래소, 블록체인 기반의 핀테크 사업(STO, 조각투자) 등 금융 디지털 혁신을 가능하게 합니다.
              </p>
            </div>
            <div className="introduceList">
              <ul>
                <li>
                  <img src={require("_Asset/img/about/introduce_ico_01.svg").default} alt="introduce_icon_01" />
                
                  <p>22년+<br/>IT 경험</p>
                </li>
                <li>
                  <img src={require("_Asset/img/about/introduce_ico_02.svg").default} alt="introduce_icon_02" />

                  <p>18개+<br/>증권 고객사</p>
                </li>
                <li>
                  <img src={require("_Asset/img/about/introduce_ico_03.svg").default} alt="introduce_icon_03" />

                  <p>최고<br/>금융 IT 전문가</p>
                </li>
              </ul>
            </div>
          </React.Fragment>
          :
          <React.Fragment>
            <div className="titWrap">
              <h2 className="contTit">{t('company_profile_tit')}</h2>
            </div>
            <div className="titInfoTxt">
              <p>{t('company_profile_info_01')}</p>
              <p dangerouslySetInnerHTML={{ __html: sanitizer(t('company_profile_info_02'))}}></p>
              <p>{t('company_profile_info_03')}</p>
              <p>{t('company_profile_info_04')}</p>
            </div>
          </React.Fragment>
        }
      </div>

      {
        htmlLang === 'ko' ?
        <React.Fragment>
          <div className="visual kor">
            <div className="inner">
              <h2>Business 3 year Planning</h2>
    
              <ul>
                <li>
                  <h3>2024</h3>
                  <p>
                    클라우드 기반 Autoscaling 채널 플랫폼 시장 점유율 - 증권사 업계 1st
                    원리금수취권 거래소 및 블록체인 기반 STO Information Exchange 서비스 런칭
                  </p>
                </li>
                <li>
                  <h3>2025</h3>
                  <p>
                    원리금수취권을 이용한 STO 서비스 런칭 – 원리금수취권 시장 활성화 블록체인 기반
                    STO Information Exchange 서비스 시장 지배력 강화 – 증권사 업계 1st
                  </p>
                </li>
                <li>
                  <h3>2026</h3>
                  <p>
                    금융 서비스 플랫폼 생태계 구축 – 다수의 서비스 공급자와 이용자가 사용 가능한 생태계
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </React.Fragment>
        :
        // Visual
        <div className="row visual about fix"></div>
      }

      {/* Experience Info */}
      <div className="experienceInfo">
        <div className="inner">
          <div className="cnt">
            <p className="num">23</p>
            <p className='infoLabel' dangerouslySetInnerHTML={{__html: sanitizer(t('company_profile_experience_info_01'))}}></p>
          </div>
          <div className="cnt">
            <p className="num">40</p>
            <p className='infoLabel' dangerouslySetInnerHTML={{__html: sanitizer(t('company_profile_experience_info_02'))}}></p>
          </div>
          <div className="cnt">
            <p className="num">40</p>
            <p className='infoLabel' dangerouslySetInnerHTML={{__html: sanitizer(t('company_profile_experience_info_03'))}}></p>
          </div>                    
        </div>
      </div>


      {/* Partner */}
      <div className="partners">
        <div className="inner">
          <div className="titWrap">
            <h2 className="contTit" dangerouslySetInnerHTML={{__html: sanitizer(t('partner_tit'))}}></h2>
          </div>          

          <div className="logoGroup">
            <figure><img src={require("_Asset/img/partners/keb_hana_bank.png")} alt={t('partner_keb_hana_bank')} /></figure>
            <figure><img src={require("_Asset/img/partners/samsung.png")} alt={t('partner_samsung')} /></figure>
            <figure><img src={require("_Asset/img/partners/mirae_asset.png")} alt={t('partner_mirae_asset')} /></figure>
            <figure><img src={require("_Asset/img/partners/korea_investment.png")} alt={t('partner_korea_investment')} /></figure>
            <figure><img src={require("_Asset/img/partners/hanhwa.png")} alt={t('partner_hanhwa')} /></figure>
            <figure><img src={require("_Asset/img/partners/nh.png")} alt={t('partner_nh')} /></figure>
            <figure><img src={require("_Asset/img/partners/kyobo.png")} alt={t('partner_kyobo')} /></figure>
            <figure><img src={require("_Asset/img/partners/daishin.png")} alt={t('partner_daishin')} /></figure>
            <figure><img src={require("_Asset/img/partners/shinyoung.png")} alt={t('partner_shinyoung')} /></figure>
            <figure><img src={require("_Asset/img/partners/koscom.png")} alt="Koscom" /></figure>
            <figure><img src={require("_Asset/img/partners/eugenefn.png")} alt={t('partner_eugenefn')} /></figure>
            <figure><img src={require("_Asset/img/partners/hyundai.png")} alt={t('partner_hyundai')} /></figure>
            <figure><img src={require("_Asset/img/partners/meritz.png")} alt={t('partner_meritz')} /></figure>
            <figure><img src={require("_Asset/img/partners/hana.png")} alt={t('partner_hana')} /></figure>
            <figure><img src={require("_Asset/img/partners/korea_finance.png")} alt={t('partner_korea_finance')} /></figure>
            <figure><img src={require("_Asset/img/partners/yonhap.png")} alt={t('partner_yonhap')} /></figure>
            <figure><img src={require("_Asset/img/partners/kakaopay.svg").default} alt={t('partner_kakaopay')} /></figure>
            <figure><img src={require("_Asset/img/partners/db_finance.png")} alt={t('partner_db_finance')} /></figure>
          </div>
        </div>
      </div>

   </div>
  )
}

export default About;