// Admin Side Menu List
const adMenuList = [
  {
    id: '1',
    title: 'Neoframe',
    children: [
      {
        ico: 'icofont-news',
        title: 'News',
        url: '/admin/news',
      }    
    ]
  },
  {
    id: '2',
    title: '관리자 설정',
    children: [
      {
        ico: 'icofont-users-alt-3',
        title: 'Account',
        url: '/admin/account',
      },   
    ]
  }  
]

module.exports = { adMenuList }