// Modules
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dompurify from "dompurify";

// API
import { NeoNews, NeoNewsUse } from '_Route/Api';

// Component
import {
  Paging, // Paging
  CurPageList, // 전체 리스트 정렬 및 현재 페이지 리스트 출력
  SetSortIco, // 정렬 아이콘 변경
  HandleSingleCheck, HandleAllCheck, // 체크박스 단일, 전체 선택
  HighlightedText // 검색어 하이라이팅
} from 'Admin/Component/AdFunc';
import Pagination from 'Admin/Component/Pagination' // Pagination
import { useCallback } from 'react';
import moment from 'moment/moment';


const AdmNeoNews = () => {

  const sanitizer = dompurify.sanitize;
  const navigate = useNavigate()
  const params = useParams();
  const location = useLocation();
  const path = location.pathname.split('/')[1];

   // Column name / Sort var
   const columnList = [
    {name: 'ID', key: 'news_no'},
    // {name: '제목', key: 'news_title'},
    {name: '내용', key: 'news_content'},
    {name: '작성자', key: 'news_author'},
    {name: '작성일', key: 'news_fd'},
    {name: '마지막 수정자', key: 'news_ma'},
    {name: '마지막 수정일', key: 'news_md'},
    {name: '언어', key: 'news_lang'},
    {name: '설정', key: 'news_use'},
  ]; 

  const newsTab = [
    {name: '전체', value: 'all'},
    {name: '한글 뉴스', value: 'ko'},
    {name: '영문 뉴스', value: 'en'},
  ]
  const [tabVal, setTabVal] = useState('all')


  /*========================================================================
  * Input onChange Func
  ========================================================================*/
  const [searchVal, setSearchVal] = useState(params.key || '');

  const onChangeSearch = useCallback(e => {
    setSearchVal(e.target.value);
  }, []);
  
  const onKeyPress = (e) => { if(e.key == 'Enter') onSearch(); }
  const onSearch = () => {
    navigate(`/admin/news${searchVal === '' ? '' : '/' + searchVal}`);
  }


  /*========================================================================
  * GET Neoframe News List
  ========================================================================*/
  // Paging 변수
  const [curPage, setCurPage] = useState(1) // 현제 페이지
  const [blockSize, setBlockSize] = useState(5) // Pagination 블럭 갯수
  const [pageSize, setPageSize] = useState(10) // 한 페이지에 노출할 게시물 갯수
  const [count, setCount] = useState('');

  // 컬럼 정렬 변수
  const [sortIdx, setSortIdx] = useState(0);
  const [sortType, setSortType] = useState('');
  const [sortState, setSortState] = useState('expand'); // expand, up(DECS), down(ASC);

  const [news, setNews] = useState([]); // Neoframe News List Data
  const [newsPage, setNewsPage] = useState([]); // Neoframe News Paging Data

  const newsList = () => {
    axios({
      method: 'GET',
      url: NeoNews,
      params: {
        news_title: params.key === undefined ? '' : params.key,
        news_lang: tabVal
      }
    })
    .then(res => {
      // console.log("GET Neoframe News List SUCCESS!", res);
      const sortData = res.data.result;
      let Data = new Array();
      sortData.map(s => {
        const parser = new DOMParser();
        let content = parser.parseFromString(s.news_content, 'text/html')
        s.news_content = content.body.textContent.substring(0, 50);
        Data.push(s);
      })
      
      setNews(CurPageList(sortData, sortType, sortState, curPage, pageSize))
      setNewsPage(res.data.pageInfo)
      setCount(res.data.result.length);
    })
    .catch(err => {
      console.error("GET Neoframe News List ERROR...", err);
    })
  }

  useEffect(() => {
    newsList()
  }, [location, pageSize, curPage, sortState, sortIdx, tabVal])

  const detailId = (e) => {
    if(e){
      sessionStorage.setItem('newsId', e);
    } else {
      sessionStorage.removeItem('newsId');
    }    
  }


  /*========================================================================
  * Check Box Function
  ========================================================================*/
  // CheckBox var
  const [checkItems, setCheckItems] = useState([]);
  
  // 일괄 삭제 함수
  const deleteFunc = () => {
    if(checkItems.length === 0) {
      alert('삭제할 뉴스를 선택해주세요.')
    } else {
      if(window.confirm(`${checkItems.length}개의 뉴스를 선택하셨습니다. \n정말 삭제하시겠습니까?`)) {
        checkItems.map((id, i) => { newsDelete(id, i) })
        setCheckItems([])
      }
    }
  }

  // Neoframe 뉴스 데이터 삭제
  const newsDelete = (id, index) => {
    axios({
      method: 'DELETE',
      url: NeoNews,
      data: {
       news_uuid: id
      }
    })
    .then(res => {
      if (index === checkItems.length - 1) {
        newsList()
      }
      // console.log(res, "Neoframe News Delete Success")
    })
    .catch(err => {
      console.error(err, "Neoframe News Delete Error...")
    })
  }

  // 사용 여부 변경
  const newsUpdateUse = (newsUuid) => {
    axios({
      url: NeoNewsUse,
      method: 'PUT',
      data: {
        news_uuid: newsUuid
      }
    })
    .then(res => {
      newsList()
    })
    .catch(err => {
      console.error("PUT Neoframe News List ERROR...", err);
    })
  }


  return (
    <>
      <div className="adContTitGroup">
        <h2><span className='titLabel'>{path.charAt(0).toUpperCase() + path.slice(1)}</span> News</h2>
        <p>Home / {path.charAt(0).toUpperCase() + path.slice(1)} / News 관리</p>
      </div>

      <div className="adContArea list">
        <div className="flexBox between">
          <div className="adListBox">
            <button type="button" className="btn xs red round_10" onClick={deleteFunc}>선택 삭제</button>
            
            <div className="fieldGroup row gap">
              <label>노출 게시물 수</label>

              <div className="field">
                <select name="" className="select sm type_1 round_10" onChange={(e) => setPageSize(e.target.value)} defaultValue="10">
                  <option value="10">10개 씩</option>
                  <option value="30">30개 씩</option>
                  <option value="50">50개 씩</option>
                  <option value="100">100개 씩</option>
                </select>
              </div>
            </div>

            <div className="tabList">
              {newsTab.map((key, i) => 
                <button key={i} type="button" className={`btn xs ${tabVal === key.value ? 'sfrtBlue' : 'blue_edge'} round_10`} value={key.value} onClick={(e) => setTabVal(e.target.value)}>{key.name}</button>
              )}
            </div>
          </div>

          <div className="adListBox">
            <div className="fieldGroup row gap">
              <label>콘텐츠 검색</label>

              <div className="field">
                <div className="inputIco">
                  <input type="text" className="input sm type_1 i_rt round_10" value={searchVal} onChange={onChangeSearch} onKeyPress={onKeyPress} placeholder="검색어를 입력해주세요." />
                  <i className="icofont-search"></i>
                </div>
              </div>
            </div>
            
            <button type="button" className="btn xs sfrtBlue round_10" onClick={() => {navigate('/admin/news/view'); detailId();}}>글 등록</button>
          </div>
        </div>

        <div className="table list_2">
          <table>
            <thead>
              <tr>
                <th className="check">
                  <div className="checkGroup">
                    <input type="checkbox" className='check sm blue' id="allCheck" onChange={(e) => setCheckItems(HandleAllCheck(e.target.checked, news, 'news_uuid'))} checked={checkItems.length === news.length && checkItems.length !== 0 ? true : false} />
                    <label htmlFor="allCheck"></label>
                  </div>
                </th>
                {columnList.map((list, i) =>
                  <th key={i} onClick={() => {setSortState(SetSortIco(i, sortIdx, sortState)); setSortIdx(i); setSortType(list.key)}}>
                    {list.name}
                    <i className={`icofont-rounded-${i === sortIdx ? sortState : 'expand'}`}></i>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {news.map((list, i) => 
                <tr key={i}>
                  <td>
                    <div className="checkGroup">
                      <input type="checkbox" className='check sm blue' id={`check_${i}`} onChange={(e) => setCheckItems(HandleSingleCheck(e.target.checked, list.news_uuid, checkItems))} checked={checkItems.includes(list.news_uuid) ? true : false} />
                      <label htmlFor={`check_${i}`}></label>
                    </div>
                  </td>
                  <td>{list.news_no}</td>
                  {/* <td dangerouslySetInnerHTML={{ __html: HighlightedText(sanitizer(list.news_title), params.key)}}></td> */}
                  <td dangerouslySetInnerHTML={{ __html: HighlightedText(sanitizer(list.news_content), params.key)}}></td>
                  <td>{list.news_author}</td>
                  <td>{moment(list.news_fd).format('YYYY-MM-DD HH:mm:ss')}</td>
                  <td>{list.news_ma}</td>
                  <td>{list.news_md}</td>
                  <td>{list.news_lang.toUpperCase()}</td>
                  <td style={{ width: '125px' }}>
                    <div className="btnArea full col">
                      <button type="button" className={`btn xs ${Number(list?.news_use) === 1 ? 'sfrtBlue' : 'blue_edge'} round_10`} onClick={() => newsUpdateUse(list.news_uuid)}>{Number(list?.news_use) === 1 ? '사용' : '미사용'}</button>
                      <button type="button" className="btn xs green round_10" onClick={() => {navigate('/admin/news/view'); detailId(list.news_uuid); }}>글 수정</button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flexBox between paginationBox">
          <p>Showing {curPage} to {pageSize} of {newsPage.count} entries</p>

          <Pagination
            type="type_1"
            size="sm"
            curPage={curPage}
            setCurPage={setCurPage}
            pageSize={pageSize}
            blockSize={blockSize}
            startBlock={Paging(curPage, blockSize, pageSize, count).startBlock}
            endBlock={Paging(curPage, blockSize, pageSize, count).endBlock}
            pageCount={Paging(curPage, blockSize, pageSize, count).pageCount}
          />
        </div>
      </div>
    </>
  )
}

export default AdmNeoNews;