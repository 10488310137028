const Pagination = ({type, size, curPage, setCurPage, pageSize, blockSize, startBlock, endBlock, pageCount}) => {
	
  const root = document.querySelector('#root');

	let btnList = [];
	for(let i = 0; i < blockSize; i++) {
		if(startBlock + i <= endBlock) {
			btnList.push(startBlock + i)
		}
	}

	const setPrevPage = () => {
		setCurPage(curPage - 1)
	}
	const setNextPage = () => {
		setCurPage(curPage + 1)
	}

  return (
    <div className={`pagination ${type} ${size}`} onClick={() => root.scrollTo({top: 0})}>
			<button type="button" className="first" onClick={() => setCurPage(1)} disabled={curPage - 1 === 0 ? true : false}><i className="icofont-rounded-double-left"></i></button>
			<button type="button" className="prev" onClick={setPrevPage} disabled={curPage - 1 === 0 ? true : false}><i className="icofont-rounded-left"></i></button>
			{
				btnList.map((key, i) => 
					<button key={i} type="button" className={i + blockSize * (startBlock / blockSize) === curPage ? 'active' : ''} onClick={() => setCurPage(key)}>{key}</button>
				)
			}
			<button type="button" className="next" onClick={setNextPage} disabled={curPage + 1 > pageCount ? true : false}><i className="icofont-rounded-right"></i></button>
			<button type="button" className="last" onClick={() => setCurPage(pageCount)} disabled={curPage + 1 > pageCount ? true : false}><i className="icofont-rounded-double-right"></i></button>
		</div>
  )
}

export default Pagination