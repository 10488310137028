// Modules
import { useTranslation } from 'react-i18next';

// CSS
import '_Asset/css/User/include/footer.css'


const Footer = () => {

  const { t } = useTranslation();
  
  return (
    <footer>
      <div className="inner">
        <div className="grid">
          <div className="address">
            <p>{t('footer_rep_name')}</p>
            <p>{t('footer_address')}</p>
            <p><a href="mailto:info@neoframe.com">info@neoframe.com</a></p>
          </div>
        </div>

        <p className='copy'>&copy; {new Date().getFullYear()} Neoframe, Inc.</p>
      </div>
    </footer>
  )
}

export default Footer;