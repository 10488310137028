// Modules
import { useTranslation } from 'react-i18next';
import dompurify from "dompurify"

// CSS
import '_Asset/css/User/solutions.css'

const Fido = () => {

  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;

  return (
    <div className="container">
      
      <div className="inner">
        <div className="titWrap">
          <h2 className="contTit">{t('fido_tit')}</h2>
        </div>

        <div className="titInfoTxt">
          <p dangerouslySetInnerHTML={{__html: sanitizer(t('fido_info'))}}></p>
        </div>

        <div className="contBox">
          <div className="subCont col">
            <img src={require("_Asset/img/solutions/fido_01.png")} alt="Fido_01" />

            <h3 className="subTit_02">{t('features_txt')}</h3>
            <div className="infoTxt">
              <p dangerouslySetInnerHTML={{__html: sanitizer(t('fido_features_info'))}}></p>
            </div>
          </div>
        </div>

        <div className="contBox">
          <div className="subCont row fido">
            <img src={require("_Asset/img/solutions/fido_02.png")} alt="Fido_02" />
            <img src={require("_Asset/img/solutions/fido_03.png")} alt="Fido_03" />
          </div>
        </div>
      </div>
   </div>
  )
}

export default Fido;